/**
 * 判断url是否是以http或https开头
 * @param {string} url 
 * @returns 返回可以访问的字符串
 */
export function CheckUrl (url) {
  const reg = /^((http|https):\/\/)/;
  if (!reg.test(url)) {
    return process.env.VUE_APP_BASEURL + url
  }
  else {
    return url
  }
}